"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";

// Suppress React error about setting state in render
try {
  if (typeof window !== "undefined") {
    const originalError = window.console.error;
    console.error = (...args) => {
      if (
        typeof args[0] === "string" &&
        args[0].includes("https://react.dev/link/setstate-in-render")
      ) {
        return;
      }
      // if your error is pointing to this, please check the next file in the error stack.
      originalError(...args);
    };
  }
} catch (error) {
  // ignore
}

export function QueryProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
    [],
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
