"use client";

import { AppConstantsOutput } from "@repo/backend/schemas";
import { createContext, useContext } from "react";

export type AppContextType = {
  constants: AppConstantsOutput;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export function AppProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: AppContextType;
}): JSX.Element {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppContextType {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
}
